import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../services/Firebase';
import Header from '../../components/Header';
import Gallery from '../../components/Gallery';
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../routing/routes';

const Child = (props) => {
    const [paintings, setPaintings] = useState([]);
    const navigate = useNavigate();

    const openStandaloneViewer = (id) => {
        if (window.innerWidth < 768) {
            console.log(`window width=${window.innerWidth}, no standalone viewer`)
            return;
        }

        // if we made it here, we can navigate
        navigate(`${ROUTES.STANDALONE_CHILD_VIEWER}/${id}`);
    }

    /**
     * Retrieve paintings list from firebase an put it into `paintings`
     */
    useEffect(() => {
        const getPaintings = async () => {
            props.firebase.orderedChildPaintings.onSnapshot(snap => {
                const paintings = [];
                snap.forEach((doc) => {
                    paintings.push({ ...doc.data(), id: doc.id });
                });
                console.log("#Art: set paintings", paintings);
                setPaintings(paintings)
            })
        }
        getPaintings();
    }, [props.firebase.orderedChildPaintings]);


    return (
        <div>
                <div style={{marginBottom: "2.3rem"}}><Header text={"My Son's Drawings"} /> </div>
            <Gallery
                paintings={paintings}
                onPaintingClicked={openStandaloneViewer}>
            </Gallery>
        </div>
    )
}

export default withFirebase(Child);
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { patchDocument, removeImage, uploadImage } from './firebase-helpers';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.db = app.firestore();
        this.storage = app.storage();
        this.auth = app.auth();
        if (document.location.hostname === "localhost") {
            this.db.useEmulator("localhost", 8080);
            this.storage.useEmulator("localhost", 9199);
            this.auth.useEmulator("http://localhost:9099");
        }

        this.paintings = this.db.collection("paintings");
        this.orderedPaintings = this.paintings.orderBy("orderKey", "desc");
        this.childPaintings = this.db.collection("childPaintings");
        this.orderedChildPaintings = this.childPaintings.orderBy("orderKey", "desc");
        this.messages = this.db.collection("messages");
        this.blogCollection = this.db.collection("blog");
        this.blog = this.db.collection("blog").orderBy("date", "desc");
    }

    /**
     * Helper to upload a new painting to to a given paintings collection
     * @param collection Collection to which new paintings should be added
     * @param file Handle to file to upload (from file selector input)
     * @param docFactory Factory for creating a new document for our collection.
     *  - expects as a parameter the image url for painting
     */
    uploadImageAndAddToCollection = async (
        collection,
        file,
        docFactory
    ) => {
        try {
            const url = await uploadImage(this.storage, file, percentage => {
                console.log('progress: ', percentage);
            });
            console.log('url', url)

            await collection.add(docFactory(url))
        }
        catch (err) {
            console.error('uploadAndAddPaintingToCollection Error:', err)
            return err;
        }
    }

    /**
     * Upload a new painting our collection
     * @param file Handle to file to upload (from file selector input)
     * @param name Name of the new painting
     * @param description New painting description
     */
    uploadPainting = async (file, name, description) => {
        await this.uploadImageAndAddToCollection(
            this.paintings,
            file,
            url => ({
                name,
                imageUrl: url,
                category: 'none',
                orderKey: Date.now(),
                description: description ?? ''
            })
        )
    }

     /**
     * Upload a new painting our collection
     * @param file Handle to file to upload (from file selector input)
     * @param name Name of the new painting
     * @param description New painting description
     */
      uploadChildPainting = async (file, name, description) => {
        await this.uploadImageAndAddToCollection(
            this.childPaintings,
            file,
            url => ({
                name,
                imageUrl: url,
                orderKey: Date.now(),
                description: description ?? ''
            })
        )
    }

    getPainting = async (collection, id) => {
        return await new Promise((resolve, reject) => {
            collection.onSnapshot(snap => {
                const paintings = [];
                snap.forEach((doc) => {
                    paintings.push({ ...doc.data(), id: doc.id });
                });
                resolve(paintings.find(p => p.id === id));
            })
        })
    }

    /**
     * Remove a painting with a given id from the paintings list
     */
    removePainting = async (collection, id) => {
        await removeImage(this.storage, collection, id)
    }

    /**
     * Update the metadata of a given painting
     */
    updatePainting = async (collection, id, patch) => {
        await patchDocument(collection, id, patch)
    }

    /** 
     * Update the contents of a blog entry
     */
    updateBlogEntry = async (id, patch) => {
        await patchDocument(this.blogCollection, id, patch)
    }

    createBlogEntry = async () => {
        await this.blogCollection.add({
            contents: "New blog post.",
            date: Date.now(),
            published: false
        })
    }

    removeBlogEntry = async (id) => {
        try {
            console.log('firebase.removeBlogEntry(): ', id)
            await this.blogCollection.doc(id).delete()
            return true
        } catch (e) {
            console.error('removeBlogEntry() error:', e)
            return false
        }
    }

    /**
     * Upload an image to firestore and return its new url
     * @param imageFile a File handle to upload
     * @return url of newly uploaded image in firestore
     */
    uploadImage = async (imageFile) => {
        try {
            const url = await uploadImage(this.storage, imageFile, percentage => {
                console.log('progress: ', percentage);
            });
            console.log('url', url)
            return url
        }
        catch (err) {
            console.error('uploadImage Error:', err)
            return err;
        }
    }

    /**
     * Send a message to our messages database.
     */
    sendMessage = async (senderName, senderEmail, message) => {
        try {
            const response = await this.messages.add({
                name: senderName,
                email: senderEmail,
                message: message
            });
            console.log('response: ', response)
        } catch (err) {
            console.error('Error sending message: ', err);
            return err;
        }
    }


    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();
}

export default Firebase;
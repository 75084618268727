import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useRef } from 'react';
import styled from 'styled-components';

const Form = styled.div`
    border: 1px solid lightgrey;
    width: 100%;
    margin: auto;
    background-color: 'rgb(235, 236, 240)';
    padding: 1rem;
    display: flex;
    justify-content: space-between;
`;

/**
 * Dumb component for browsing and selecting a file
 * @param props: {
 *  allowMultiple: whether multiple file selection is possible
 *  submitButtonLabel: label to use on submit button
 *  filenames: names of files to show 
 *  filesSelected(File[]): raised when new files are selected
 *  submit()
 * }
 */
const FilePicker = props => {
    const fileInputRef = useRef();

    const handleFileInput = event => {
        console.log("handleFileInput", event)
        props.filesSelected(Array.from(event.target.files))
    }

    const onSubmit = async event => {
        console.log("handleFileUpload", event)
        event.preventDefault();
        props.submit()
    }

    return (
        <Form>
            <Button 
                variant="contained"
                onClick={()=>fileInputRef.current.click()}
                color="secondary">Choose {props.allowMultiple ? "file(s)" : "files"} </Button>
            <input
                multiple={props.allowMultiple ?? false}
                type="file"
                onChange={handleFileInput}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <TextField
                sx={{ flexGrow: '1', marginLeft: '1rem', marginRight: '1rem'}}
                disabled
                id="file-path"
                label="selected file"
                value={props.filenames?.join(', ') ?? ''}
            />
            <Button 
                variant="contained"
                onClick={onSubmit}>{props.submitButtonLabel ?? 'Submit'}
            </Button>
        </Form>
    )
}

export default FilePicker
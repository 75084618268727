import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { withFirebase } from '../../services/Firebase';

const ScreenContainer = styled.div`
    height: 100%;
    margin-top: -20px;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    max-width: 95%;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 75vh;
    max-width: 95%;
`;

/**
 * Viewer for a painting. Painting is passed in as a prop
 */
const PaintingViewer = (props) => {
    const [painting, setPainting] = useState(undefined);

    useEffect(() => {
        async function retrievePainting() {
            const retrievedPainting = await props.firebase.getPainting(
                props.paintings,
                props.id
            );
            setPainting(retrievedPainting);
        }
        retrievePainting()
       
    }, [props.paintings, props.id]);

    return (
        <ScreenContainer>
            {painting &&
                <ImageContainer>
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        align="center">{painting.name}</Typography>
                    <Image src={painting.imageUrl}></Image>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="left"
                        style={{ marginTop: `1rem` }}>
                        {painting.description}
                    </Typography>
                </ImageContainer>
            }
        </ScreenContainer>
    );
}

export default withFirebase(PaintingViewer);
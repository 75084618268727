/**
 * Upload an image to firebase storage.
 * @param storage firebase storage object 
 * @param file file to upload (from an in put file selector)
 * @param onProgressCb callback function called whenever progress event is raised.
 *                     take a percentage value (between 0 and 100) as a parameter.
 * @returns download URL if upload was successful, undefined otherwise
 */
export function uploadImage(storage, file, onProgressCb) {
    return new Promise((resolve, reject) => {
        try {
            const storageRef = storage.ref(file.name)

            storageRef.put(file).on(
                'state_changed',
                (snap) => {
                    onProgressCb && onProgressCb((snap.bytesTransferred / snap.totalBytes) * 100)
                },
                error => reject(error),
                () => {
                    storageRef.getDownloadURL().then(url => resolve(url))
                })
        }
        catch (err) {
            reject(err)
        }
    })
}



/**
 * Remove an image from storage and its collection.
 * @param storage Firebase storage object
 * @param collection Firestore collection that contains this image 
 * @param id of the image within its collection
 * @returns true on success, false on fail
 * 
 * Assumes that each document has a unique "name" field.
 */
export async function removeImage(storage, collection, id) {
    try {
        const doc = await collection.doc(id)
        const storageDest = (await doc.get()).data().name
        await doc.delete()
        await storage.ref().child(storageDest).delete()
        return true
    } catch (e) {
        console.error('removeImage() error:', e)
        return false
    }
}

/**
 * Patch a document, given its id and the collection to which it 
 * belongs.
 * @returns an error object if there was an error, otherwise nothing
 */
export async function patchDocument(collection, id, patch) {
    try {
        const doc = await collection.doc(id)
        await doc.update(patch);
    } catch (err) {
        console.error('Error updating document: ', { id, err });
        return err;
    }
}
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import FilePicker from '../../FilePicker/FilePicker';

const Upload = styled.div`
    border: solid 1px;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    width: 300px;
    padding: 1rem;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    z-index: 200;
    background-color: white;
`;

const FilePickerContainer = styled.div`
    width: 800px;
    margin: auto;
`;

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    z-index: 100;
`;

/**
 * Smart file selector component. Allows user to select a set of files
 * and trigger an upload to a firebase collection.
 * @param props: {
 *  firebaseFiles: firebase collection with images.
 *  uploadFile(File, string): callback for uploading each selected file. 
 * }
 * @returns 
 */
const FileUploader = props => {
    const [files, setFiles] = useState([]);
    const [filesCount, setFilesCount] = useState(0);
    const [beforeUploadCount, setBeforeUploadCount] = useState(0)
    const [afterUploadCount, setAfterUploadCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [showUploading, setShowUploading] = useState(false);

    /**
     * Track total number of paintings in our firebase list
     */
    useEffect(() => {
        const unsubscribe = props.firebaseFiles.onSnapshot(snap => {
            if (!snap.empty) {
                setFilesCount(snap.size);
            }
        });
        return () => unsubscribe();
    }, [props.firebaseFiles])

    const handleFileInput = files => {
        console.log("handleFileInput", files)
        setFiles(files);
    }

    /**
     * Toggle uploading state, depending on paintingsCount
     */
    useEffect(() => {
        console.log("paintingscount effect", filesCount)
        if (uploading && filesCount >= afterUploadCount) {
            setUploading(false);
        }
    }, [filesCount, afterUploadCount, uploading])

    const handleFileUpload = () => {
        if(files.length === 0){
            alert('No files selected');
            return;
        }
        setShowUploading(true);
        setUploading(true);
        setBeforeUploadCount(filesCount);
        setAfterUploadCount(files.length + filesCount);
        
        files.forEach((file) => {
            if(file === '') {
                console.error(`not an image, the image file is a ${typeof(image)}`)
                props.setLoading(false);
            }
            try {
                props.uploadFile(file, file.name);
            }
            catch(err){
                console.log(err);
            }
        })
        setFiles([])
    }

    return (
        <div>
            <FilePickerContainer>
                <FilePicker 
                    allowMultiple={true}
                    filenames={files.map(f => f.name)}
                    filesSelected={handleFileInput}
                    submit={handleFileUpload}/>
            </FilePickerContainer>

            {showUploading ? 
            <div>
                <Upload>
                    <h3>
                        {uploading? 'Uploading' : 'Upload finished'}
                    </h3>
                    <p>
                        Uploaded: {filesCount - beforeUploadCount }/{afterUploadCount - beforeUploadCount} images.
                    </p>
                    {!uploading ? <button onClick={() => setShowUploading(false)}>Close</button> : null}
                    
                </Upload> 
                <Overlay />
            </div>: null}
        </div>
    )
}

export default FileUploader
export const LANDING = '/';
export const SIGN_IN = '/signin';
export const BIO = '/bio';
export const CONTACT = '/contact';
export const CHILD = '/child';
export const BLOG = '/blog';
export const MESSAGE_SUBMITTED = '/message-submitted';
export const ADMINLANDING = '/admin'
export const ADMINART = '/admin/art';
export const ADMINCHILD = '/admin/child';
export const ADMINBLOG = '/admin/blog';
export const STANDALONE_ART_VIEWER = '/painting';
export const STANDALONE_CHILD_VIEWER = '/child-painting';
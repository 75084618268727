import { Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../routing/routes';
import { withFirebase } from '../../services/Firebase';
import { globalStyles } from '../../styling/global-styles.const';
import classes from './style.module.css';

/**
 * Styles for FAB used to create a new blog entry
 */
const createEntryFabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    color: 'common.white',
    bgcolor: globalStyles.button.background,
    '&:hover': {
      bgcolor: globalStyles.button.backgroundHover,
    },
  };

/**
 * Main blog page. Lists all entries in blog, ordered by date.
 * If logged in as administration, controls for CRUD operations are also shown.
 */
const Blog = props => {
    const [blogEntries, setBlogEntries] = useState([]);
    const [user] = useAuthState(props.firebase.auth);
    const navigate = useNavigate();

    /**
    * Retrieve list of blog entries and put them into `entries`
    */
    useEffect(() => {
        const getBlogEntries = async () => {
            props.firebase.blog.onSnapshot(snap => {
                const entries = [];
                snap.forEach((doc) => {
                    const entry = doc.data()
                    entries.push({
                        ...entry,
                        id: doc.id,
                        // what could possibly go wrong here...
                        dateString: new Date(entry.date).toDateString()
                    });
                });
                console.log("#Blog: set entries", entries);
                setBlogEntries(entries)
            })
        }
        getBlogEntries();
    }, [props.firebase.blog]);

    function openEditor(id) {
        navigate(`${ROUTES.ADMINBLOG}/${id}`);
    }

    async function createEntry() {
        await props.firebase.createBlogEntry()
    }

    async function deleteEntry(id) {
        console.log('deleteEntry(): ', id)
        // eslint-disable-next-line no-restricted-globals
        var dialog = confirm("Are you sure you want to delete the blog post?");
        if (dialog) {
            await props.firebase.removeBlogEntry(id)
            console.log('Post deleted')
        }
    }

    async function publish(entry) {
        console.log('publish(): ', entry)
        await props.firebase.updateBlogEntry(entry.id, { published: true })
    }

    async function unpublish(entry) {
        console.log('unpublish(): ', entry)
        await props.firebase.updateBlogEntry(entry.id, { published: false })
    }

    function getImagesJsx(entry) {
        if (Array.isArray(entry.imgUrl)) {
            return entry.imgUrl.map((imgUrl, index) => {
                return (
                    <img className={classes.image} src={imgUrl} key={index}></img>
                )
            })
        } else {
            return (
                <img className={classes.image} src={entry.imgUrl}></img>
            )
        }
    }

    function getBlogEntryJsx(entry) {
        return (
            <div className={classes.entryContent} key={entry.date}>
                <div className={classes.header}>
                    <div className={classes.headerDate}>
                        {entry.dateString}
                    </div>
                    { user && <div className={classes.headerAdmin}>
                        <Button variant="contained"
                                onClick={() => openEditor(entry.id)}>Edit</Button>
                        <Button variant="contained"
                                onClick={() => deleteEntry(entry.id)}>Delete</Button>
                        { entry.published === false && 
                                <Button variant="contained"
                                onClick={() => publish(entry)}>Publish</Button> }
                        { entry.published === true && 
                                <Button variant="contained"
                                onClick={() => unpublish(entry)}>Unpublish</Button> }
                    </div> }
                </div>
                <Stack 
                    sx={{width: "95%", flexGrow: 1}} 
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    flexWrap="wrap">
                    { getImagesJsx(entry) }
                </Stack>
                <Box
                    sx={{width: "95%"}} 
                    variant="body1"
                    gutterBottom>
                    {getEntryTextJsx(entry.contents)}
                </Box>
            </div>
        )
    }

    function getEntryTextJsx(entryText) {
        console.log('getEntryText(): ', entryText?.split('\n'))
        return (
            <div>
                {
                    entryText?.split('\n')?.map((line, index) =>
                        ( <Typography
                                variant="body1"
                                key={index}>{line === '' ? (<span>&nbsp;</span>) : line}</Typography> )
                    )
                }
            </div>
        )
    }

    return (
        <div>
            {blogEntries.filter(entry => user || entry.published === true)
                        .map(getBlogEntryJsx)}
            { blogEntries.filter(entry => entry.published).length === 0 && 
                <Stack justifyContent="center" direction="column">
                    <Typography
                        variant="h6" 
                        sx={{textAlign: "center"}}>No entries yet
                    </Typography>
                </Stack>
            }
            {user && <Fab 
                sx={createEntryFabStyle}
                aria-label="add"
                onClick={createEntry}>+</Fab> }
        </div>
    )
}

export default withFirebase(Blog);
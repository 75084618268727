import React from 'react';
import Typography from '@mui/material/Typography';

const MessageSubmitted = () => (
    <Typography 
        variant="body"
        component="div"
        sx={{width: "100%", textAlign: "center"}}>
        Your message has been submitted. Thank you for your feedback.
    </Typography>
);

export default MessageSubmitted;
import React, { useState } from 'react';
import { withFirebase } from "../../services/Firebase"
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import * as ROUTES from '../../routing/routes';

const Contact = props => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    function onSubmit() {
        props.firebase.sendMessage(name, email, message)
        navigate(ROUTES.MESSAGE_SUBMITTED);
    }

    return (
        // <div class={classes.container}>
        <Container sx={{ paddingTop: "2rem" }}>
            <Stack 
                spacing={2}
                direction={{ xs: 'column', md: 'row' }}>
                <Stack spacing={2} sx={{ flexGrow: 1 }} >
                    <Typography variant="h4" gutterBottom component="div">
                        Contact me
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Like my art? Do you have some comment or feedback? Or maybe you just want to say hi :) Whatever is on your mind, I would love to hear from you.
                    </Typography>
                </Stack>
                <Stack spacing={2}
                    sx={{
                        width: { xs: "100%", md: "50%" },
                        flexShrink: 0
                    }
                    }>
                    <TextField
                        id="contact-name"
                        label="name"
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)} />
                    <TextField
                        id="contact-email"
                        label="email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                    <TextField
                        id="contact-message"
                        label="message"
                        variant="outlined"
                        minRows={10}
                        multiline={true}
                        value={message}
                        onChange={e => setMessage(e.target.value)} />
                    <Button
                        variant="contained"
                        onClick={onSubmit}>Submit</Button>
                </Stack>
            </Stack>
        </Container>
        // </div>
    )
}

export default withFirebase(Contact);
import React from 'react';
import Typography from '@mui/material/Typography';

const Header = (props) => (
    <Typography 
        variant="h4"
        gutterBottom
        component="div"
        sx={{width: "100%", textAlign: "center"}}>
        {props.text.toUpperCase()}  
    </Typography>
);

export default Header;



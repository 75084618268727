import { useState, useEffect } from "react";

/**
 * Custom hook for management of a list of editable paintings
 * @param {*} firebasePaintings 
 * @param {*} firebaseDb 
 * @param {*} getDoc 
 * @param {*} updateFirebasePainting 
 * @param {*} removeFirebasePainting 
 * @returns {
 *   paintings: Painting[]
 *   updatePainting: (id, patch) -> void,
 *   removePainting: (index, id) -> void,
 *   movePainting: (sourceIndex, destIndex) -> void
 * }
 */
export default function useEditablePaintings(
    firebasePaintings,
    firebaseDb,
    getDoc, // id) -> docRef
    updateFirebasePainting, // (id, patch) -> void
    removeFirebasePainting // (id) -> void
) {
    const [paintings, setPaintings] = useState([]);

    useEffect(() => {
        const getPaintings = async () => {
            firebasePaintings.onSnapshot(snap => {
                const paintings = [];
                snap.forEach((doc, index) => {
                    console.log("#PaintingList: docs, index", { doc, index });
                    const paintingData = doc.data();
                    paintings.push({
                        ...paintingData,
                        id: doc.id
                    });
                });
                console.log("#PaintingList: set paintings", paintings);
                setPaintings(paintings)
            })
        }
        getPaintings();
    }, [firebasePaintings]);

    const movePainting = (sourceIndex, destIndex) => {
        console.log('movePainting(): source: ', paintings[sourceIndex]);
        console.log('movePainting(): dest: ', paintings[destIndex]);

        // we want to swap source and dest orderKeys, and do it as batch
        const batch = firebaseDb.batch();

        // create a new painting list with our dropped painting at its new location
        const newPaintingList = [...paintings];
        newPaintingList.splice(sourceIndex, 1);
        newPaintingList.splice(destIndex, 0, paintings[sourceIndex]);

        // Iterate through our new list an copy the orderKeys from old list
        // This will enforce new ordering on our database
        newPaintingList.forEach((painting, index) => {
            const newOrderKey = paintings[index].orderKey
            console.log(
                `batch operation: updating ${painting.name}: ` +
                `orderKey ${painting.orderKey} => ${newOrderKey}`
            )
            batch.update(
                getDoc(painting.id),
                { orderKey: newOrderKey }
            )
        })

        // commit our updates to the database
        batch.commit().then(result => {
            console.log('batch commit done: ', result);
        });

        // do an optimistic update of our new paintings
        setPaintings(newPaintingList);
    }

    const updatePainting = (index, patch) => {
        const updatedPainting = {
            ...paintings[index],
            ...{ patch }
        }

        const newPaintingList = [...paintings];
        newPaintingList.splice(index, 1, updatedPainting);
        setPaintings(newPaintingList);
        updateFirebasePainting(updatedPainting.id, patch)
    }

    const removePainting = (i, id) => {
        const newPaintingList = [...paintings];
        newPaintingList.splice(i, 1);
        setPaintings(newPaintingList);
        removeFirebasePainting(id);
    }

    return {
        paintings,
        updatePainting,
        removePainting,
        movePainting
    }

}
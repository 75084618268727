import React from "react";
import { withFirebase } from '../../../services/Firebase';
import EditPaintings from './EditPaintings/EditPaintings.js';
import FileUploader from "../../../components/Admin/FileUploader";

/**
 * Art page for logged in administrator.
 */
const AdminArt = props => {
  return (
    <div>
      <FileUploader
          firebaseFiles={props.firebase.paintings}
          uploadFile={props.firebase.uploadPainting}>
        </FileUploader> 
      <EditPaintings />
    </div>
  )
}

export default withFirebase(AdminArt);

import { createTheme } from '@mui/material/styles';
import { globalStyles } from '../styling/global-styles.const';

export default function createAppTheme() {
    return createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        background: globalStyles.button.background,
                        '&:hover': {
                            background: globalStyles.button.backgroundHover,
                        }
                    },
                }
            }
        }
    });
}


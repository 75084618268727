import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet
  } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { withFirebase } from './services/Firebase';
import * as ROUTES from './routing/routes';

import styled from 'styled-components';

import Art from './views/Art'
import Child from './views/Child'
import Bio from './views/Bio';
import AdminArt from './views/Admin/Art';
import AdminChild from './views/Admin/Child';
import Nav from './components/Nav';
import Contact from './views/Contact';
import SignIn from './views/Admin/SignIn'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import MessageSubmitted from './views/Contact/MessageSubmitted';
import { globalStyles } from './styling/global-styles.const';
import ArtViewer from './views/ArtViewer'
import createAppTheme from './services/create-app-theme'
import ChildArtViewer from './views/ChildArtViewer';
import Blog from './views/Blog';
import EditBlog from './views/Admin/EditBlog/EditBlog';

// log build version for debugging
console.log("BUILD_VERSION: ", 	process.env.REACT_APP_VERSION)

// setup app styling theme
const theme = createAppTheme()

/**
 * Container for main page content
 */
const Container = styled.div`
  margin-top: ${globalStyles.screenTopOffset};
  height: calc(100vh - ${globalStyles.screenTopOffset});
`;

/**
 * Base app screen. Defines root screen, containing:
 * - Navigation header
 * - Router
 */
const AppRoot = props => {
    const [user] = useAuthState(props.firebase.auth);

    return(
        <Router>
          <ThemeProvider theme={theme}>
           	<CssBaseline />
            <Nav />
            <Container>
                <Routes>
                    <Route path="*" element={<Art />} />
                    <Route path={ROUTES.LANDING} element={<Art />} />
                    <Route path={ROUTES.ADMINLANDING} element={<SignIn />} />
                    {user && (
                      <Route path={ROUTES.ADMINART} element={<AdminArt />} />
                    )}
                    <Route path={ROUTES.STANDALONE_ART_VIEWER} element={<Outlet/>}>
                      <Route path=":id" element={<ArtViewer/>} />
                    </Route>
                    <Route path={ROUTES.STANDALONE_CHILD_VIEWER} element={<Outlet/>}>
                      <Route path=":id" element={<ChildArtViewer/>} />
                    </Route>
                    <Route path={ROUTES.BIO} element={<Bio/>} />
                    <Route path={ROUTES.BLOG} element={<Blog/>} />
                    {user && (
                      <Route path={ROUTES.ADMINBLOG} element={<Outlet />}>
                        <Route path=":id" element={<EditBlog/>} />
                      </Route>
                    )}
                    <Route path={ROUTES.CHILD} element={<Child/>} />
                    {user && (
                      <Route path={ROUTES.ADMINCHILD} element={<AdminChild />} />
                    )}
                    <Route path={ROUTES.CONTACT} element={<Contact/>} />
                    <Route path={ROUTES.MESSAGE_SUBMITTED} element={<MessageSubmitted/>} />
                </Routes>
            </Container>
            </ThemeProvider>
        </Router>
  );
}

export default withFirebase(AppRoot);
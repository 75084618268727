import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withFirebase } from '../../../../services/Firebase';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Painting = props => {
    const [inpName, setInpName] = useState(props.name);
    const [inpCategory, setInpCategory] = useState(props.category);
    const [inpDescription, setInpDescription] = useState(props.description);
    
    useEffect( () => {
        props.handleUpdateName(inpName, props.index);
    // eslint-disable-next-line
    }, [inpName, props.index])

    useEffect( () => {
        props.handleUpdateCategory(inpCategory, props.index);
    // eslint-disable-next-line
    }, [inpCategory, props.index])

    useEffect( () => {
        props.handUpdateDescription(inpDescription, props.index);
    // eslint-disable-next-line
    }, [inpDescription, props.index])

    return (
        <Draggable
            draggableId={props.id} index={props.index}
        >
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    sx={{marginBottom: '2rem', border: '1px dashed grey', padding: '.5rem'}}>
                <Card variant="outlined"
                      sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                        
                        >
                    <CardContent>

                        <Stack spacing={2} sx={{ width: 300 }}>
                            <TextField 
                                id="input-name"
                                label="name"
                                variant="outlined"
                                value={inpName}
                                onChange={e => setInpName(e.target.value)}/>
                            { props.showCategory && 
                                <TextField 
                                id="input-category"
                                label="category"
                                variant="outlined"
                                value={inpCategory}
                                onChange={e => setInpCategory(e.target.value)}/>
                            }
                            </Stack>
                        </CardContent>
                        <CardMedia 
                                component="img"
                                sx={{ width: 300 }}
                                image={props.imageUrl}
                                alt={props.name}
                                >

                        </CardMedia>
                        <Button 
                        variant="text"
                        onClick={() => props.removeImage(props.index, props.id)}>X</Button>
                        </Card>

                        <TextField 
                                id="input-description"
                                label="description"
                                variant="outlined"
                                sx={{width: '100%'}}
                                value={inpDescription}
                                minRows={2}
                                multiline={true}
                                onChange={e => setInpDescription(e.target.value)}/>

                        </Box>
            )}
        </Draggable>
    )
}

export default withFirebase(Painting);
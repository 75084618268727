import React from "react";
import { withFirebase } from '../../../../services/Firebase';
import EditablePaintingList from "../../../../components/Admin/EditablePaintingList";
import useEditablePaintings from "../../../../services/use-editable-painting.hook"

/**
 * An editable list of all paintings on the Art page
 */
const EditPaintings = props => {
    const firebase = props.firebase
    const {
        paintings,
        updatePainting,
        removePainting,
        movePainting
    } = useEditablePaintings(
        firebase.orderedPaintings,
        firebase.db,
        id => firebase.paintings.doc(id),
        (id, patch) => firebase.updatePainting(
            firebase.paintings,
            id,
            patch
        ),
        id => firebase.removePainting(
            firebase.paintings,
            id
        ),
    )

    return (
        <EditablePaintingList
            paintings={paintings}
            showCategory={true}
            updatePainting={updatePainting}
            removePainting={removePainting}
            movePainting={movePainting}
        />
    );
}

export default withFirebase(EditPaintings);
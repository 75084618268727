import React from "react";
import { withFirebase } from '../../../services/Firebase';
import EditChildPaintings from './EditChildPaintings/EditChildPaintings.js';
import FileUploader from "../../../components/Admin/FileUploader";

/**
 * Child Art page for logged in administrator.
 */
const AdminChild = props => {
  return (
    <div>
      <FileUploader
          firebaseFiles={props.firebase.childPaintings}
          uploadFile={props.firebase.uploadChildPainting}>
      </FileUploader> 
      <EditChildPaintings />
    </div>
  )
}

export default withFirebase(AdminChild);

import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const StyledImage = styled.img`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${props => props.isLoaded ? 1 : 0};
    transition: opacity 1s;
`;

const ImageWrapper = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: ${props => props.isLoaded ? 'auto' : `300px`};
`;

const LazyImage = ({ src, alt, once }) => {
const [isLoaded, setIsLoaded] = useState(false)
  const removePlaceholder = () => {
    setIsLoaded(true)
  };

  return (
    <ImageWrapper isLoaded={isLoaded}>
      <LazyLoad once={once} offset={300}>
        <StyledImage
            isLoaded={isLoaded}
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src}
          alt={alt}
        />
      </LazyLoad>
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;

import React from 'react';
import { withFirebase } from '../../services/Firebase';
import PaintingViewer from '../../components/PaintingViewer';
import { useParams } from "react-router-dom";

/**
 * Standalone viewer screen for a single painting
 */
const ArtViewer = (props) => {
    const params = useParams()

    return (
        <PaintingViewer 
            paintings={props.firebase.orderedPaintings}
            id={params.id} 
            />
    );
}

export default withFirebase(ArtViewer);
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import LazyImage from '../../components/LazyImage';
import classes from './style.module.css';

/**
 * Dumb component for displaying a gallery of paintings.
 * 
 * Paintings are passed in as an array: props.paintings. 
 * Each painting in the array must contain the following
 * fields: 
 *  - id: string
 *  - category: string
 *  - name: string
 *  - description: string
 *  - imageUrl: string
 * 
 * Events raised:
 *  - props.onPaintingClicked(id: string)
 */
const Gallery = (props) => {
    const [doNotRenderCategorized, setDoNotRenderCategorized] = useState([]);
    const [categorizedPaintings, setCategorizedPaintings] = useState([]);

    /**
     * Whenever props.paintings is update, recompute our categorization.
     */
    useEffect(() => {
        const categories = [...new Set(props.paintings.map(p => p.category))]
        const partitions = categories.map(cat => {
            return {
                category: cat,
                paintings: props.paintings.filter(p => p.category === cat)
            }
        })
        setCategorizedPaintings(partitions)

        setDoNotRenderCategorized(
            partitions.length === 1 && !(partitions[0].category)
        )
    }, [props.paintings]);


    /**
     * Render all paintings in a list
     * @returns 
     */
    function renderPaintings(paintingsToRender) {
        return paintingsToRender.map((painting) => (
            <div
                key={painting.id}
                className={classes.divPainting}
                onClick={() => props.onPaintingClicked(painting.id)}>
                <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    className={classes.showOnSmallScreen}
                    align="center">{painting.name}</Typography>

                <LazyImage
                    className={classes.paintingTile}
                    src={painting.imageUrl}
                    alt={painting.name}
                    once={true} />

                <Typography
                    variant="body2"
                    color="text.secondary"
                    align="left"
                    className={classes.caption + " " + classes.showOnSmallScreen}>
                    {painting.description}
                </Typography>
            </div>
        ))
    }

    /**
     * Return JSX for all paintings, grouped into categories.
     */
    function renderCategorized() {
        console.log('#Gallery.renderCategorized()')
        return categorizedPaintings.map((item) => {
            return (
                <div key={item.category} className={classes.categoryContainer}>
                    <div className={classes.categoryHeader}>{item.category}</div>
                    <div className={classes.paintings}>
                        {renderPaintings(item.paintings)}
                    </div>
                </div>
            )
        })
    }

    /**
     * REturn JSX for all paintings as a single, uncategorized gallery
     */
    function renderUncategorized() {
        console.log('#Gallery.renderUncategorized()')
        return categorizedPaintings.map(item => {
            return (
                <div className={classes.paintings}>
                    {renderPaintings(item.paintings)}
                </div>
            )
        })
    }

    // render
    if (doNotRenderCategorized) {
        return renderUncategorized()
    } else {
        return renderCategorized()
    }
}

export default Gallery
import React from "react";
import styled from 'styled-components';
import Painting from './Painting/Painting.js';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const Container = styled.div`
    width: 800px;
    margin: auto;
    margin-top: 75px;
    background-color: 'rgb(235, 236, 240)';
`;
const List = styled.div`
    padding: 8px;
    transition: background-color 0.2s ease;
    background-color: ${props => props.isDraggingOver ? 'rgb(255,235,230)' : 'white'}
`;

/**
 * Component that provides a list of paintings that can be edited.
 * @param props: 
 *  - paintings: list of paintings to display. painting fields: {
 *      id: string,
 *      name: string,
 *      imageUrl: string,
 *      category: string,
 *      description: string
 *     }
 *  - showCategory: boolean
 * 
 * Outputs:
 *  - updatePainting(index: number, patch: Object)
 *  - removePainting(index: number, id: string)
 *  - movePainting(fromIndex: number, toIndex: number)
 */
const EditablePaintingList = props => {

    const handleDragEnd = result => {
        const { destination, source } = result;
        console.log('handleDragEnd: ', { destination, source })

        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) return;

        props.movePainting(source.index, destination.index)
    }
    
    const handleUpdateName = (newName, i) => {
        props.updatePainting(i, {name: newName})
    }

    const handleUpdateCategory = (newCategory, i) => {
        if (props.showCategory) {
            props.updatePainting(i, {category: newCategory})
        }
    }

    const handleUpdateDescription = (newDescription, i) => {
        props.updatePainting(i, {description: newDescription})
    }

    return (
        <DragDropContext
            onDragEnd={handleDragEnd}>
            <Container>
                <Droppable droppableId="1">
                    {(provided, snapshot) => (
                        <List
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {props.paintings.map(
                                (painting, index) =>
                                    <Painting
                                        key={painting.id}
                                        id={painting.id}
                                        name={painting.name}
                                        imageUrl={painting.imageUrl}
                                        index={index}
                                        showCategory={props.showCategory}
                                        category={painting.category}
                                        description={painting.description}
                                        handleUpdateName={handleUpdateName}
                                        handleUpdateCategory={handleUpdateCategory}
                                        handUpdateDescription={handleUpdateDescription}
                                        removeImage={props.removePainting}
                                    >
                                    </Painting>)}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </Container>
        </DragDropContext>
    );
}

export default EditablePaintingList;
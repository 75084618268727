import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import MultiFilePicker from '../../../components/MultiFilePicker/MultiFilePicker';
import * as ROUTES from '../../../routing/routes';
import { withFirebase } from '../../../services/Firebase';
/**
 * Screen for editing a Blog post with a specific id.
 */
const EditBlog = props => {
    const params = useParams()
    const navigate = useNavigate()
    const [imageUrls, setImageUrls] = useState([]);
    const [contents, setContents] = useState('');
    const [date, setDate] = useState(new Date(Date.now()))

    console.log('params id', params.id)

    // this will be called each time the contents text field changes
    const onUpdateContents = (newContents) => {
        setContents(newContents)
    }

    const onMultiSubmit = async (params) => {
        console.log('onMultiSubmit: ', params)
    }

    // this will be called when user actually submits their edited contents
    const onSubmit = async () => {
        console.log('onSubmit, date: ', date)

        // make sure we have an image
        if (!imageUrls || imageUrls.length === 0) {
            alert(
                "No image has been uploaded. Currently every blog post requires at least one image."
            );
            return;
        }

        const patch = {
            contents,
            imgUrl: imageUrls,
            date: date.getTime()
        }
        console.log('onSubmit, patch: ', patch)
        await props.firebase.updateBlogEntry(params.id, patch)
        navigate(ROUTES.BLOG)
    }

    const onCancel = () => {
        navigate(ROUTES.BLOG)
    }

    const updateImages = async ({files}) => {
        console.log(`updateImage: id=${params.id}, new images=${files}`)

        const uploadPromises = files.map(async (imageFile) => {
            const url = await props.firebase.uploadImage(imageFile)
            return url
        })

        const urls = await Promise.all(uploadPromises)

        console.log(`updateImage: urls=${urls}`)
        setImageUrls(urls)
    }

    const getDateString = (dateNumberMs) => {
        const d = new Date(dateNumberMs)
        console.log('getDateString(): ', { d, t: typeof (d) })
        const month = `${d.getUTCMonth() + 1}`.padStart(2, '0')
        const day = `${d.getUTCDate()}`.padStart(2, '0')
        const result = `${d.getUTCFullYear()}-${month}-${day}`
        console.log('getDateString(): returned', result)
        return result
    }

    const onDatePicked = (pickedDate) => {
        console.log('onDatePicked: ', pickedDate)
        setDate(new Date(pickedDate))
    }

    /**
    * Retrieve blog entry to be edited and put into entry
    */
    useEffect(() => {
        const getBlogEntry = async (id) => {
            props.firebase.blogCollection.doc(id).onSnapshot(doc => {
                const entry = {
                    ...doc.data()
                }
                console.log("#EditBlog: set entry", entry);
                // setBlogEntry(entry)
                setContents(entry.contents)
                setImageUrls(entry.imgUrl)
                setDate(new Date(entry.date))
            })
        }
        getBlogEntry(params.id);
    }, []);

    function getImagesJsx() {
        if (!imageUrls || imageUrls.length === 0) {
            return <div>No images uploaded</div>
        }
        return imageUrls.map((url, index) => {
            return <CardMedia component='img'
                key={index}
                image={url}>
            </CardMedia>
        })
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
        }}>
            <Card variant="outlined"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '70%',
                    flexDirection: "column",
                    alignItems: "center",
                }}>

                <MultiFilePicker 
                    submitButtonLabel="Upload"
                    submit={updateImages}>
                </MultiFilePicker>

                {getImagesJsx()}

                <CardContent sx={{ width: '100%' }}>

                    <Stack direction="row">
                        <TextField
                            id="date"
                            label="Title"
                            type="date"
                            value={getDateString(date)}
                            sx={{ width: 220 }}
                            onChange={e => onDatePicked(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => setDate(new Date(Date.now()))}>Today</Button>
                    </Stack>

                    <TextField
                        sx={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        rows={20}
                        multiline
                        value={contents}
                        onChange={e => onUpdateContents(e.target.value)}
                    />
                    <Button variant="contained" onClick={onSubmit}>Submit</Button>
                    <Button variant="contained" onClick={onCancel}>Cancel</Button>
                </CardContent>
            </Card>
        </Box>


    )
}

export default withFirebase(EditBlog)
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.div`
    border: 1px solid lightgrey;
    width: 100%;
    margin: auto;
    background-color: 'rgb(235, 236, 240)';
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const MultiFilePickerRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const MultiFilePicker = (props) => {
    const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);
    const addFileInput = () => {
        setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);
    };

    const removeFileInput = (id) => {
        setFileInputs(fileInputs.filter(input => input.id !== id));
    };

    const handleFileInput = (event, id) => {
        const file = event.target.files[0];
        const updatedInputs = fileInputs.map(input =>
            input.id === id ? { ...input, file } : input
        );
        setFileInputs(updatedInputs);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const selectedFiles = fileInputs
            .map(input => input.file)
            .filter(Boolean)
        props.submit({ files: selectedFiles});
    };

    return (
        <Form>
            {fileInputs.map(({ id, file }) => (
                <Stack key={id} direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        onClick={() => document.getElementById(`file-input-${id}`).click()}
                        color="secondary"
                    >
                        Choose file
                    </Button>
                    <input
                        id={`file-input-${id}`}
                        type="file"
                        onChange={(e) => handleFileInput(e, id)}
                        style={{ display: 'none' }}
                    />
                    <TextField
                        sx={{ flexGrow: '1', marginLeft: '1rem', marginRight: '1rem' }}
                        disabled
                        id={`file-path-${id}`}
                        label="selected file"
                        value={file ? file.name : ''}
                    />
                     <Button
                        variant="contained"
                        onClick={() => removeFileInput(id)}
                        color="secondary">
                        x
                    </Button>
                </Stack>
            ))}
            <Button variant="contained" onClick={addFileInput}>
                Add
            </Button>
            <Button variant="contained" onClick={onSubmit}>
                {props.submitButtonLabel ?? 'Submit'}
            </Button>
        </Form>
    );
};

export default MultiFilePicker;

import React from 'react';
import { withFirebase } from '../../services/Firebase';
import PaintingViewer from '../../components/PaintingViewer';
import { useParams } from "react-router-dom";

/**
 * Standalone viewer screen for 
 */
const ChildArtViewer = (props) => {
    const params = useParams()

    return (
        <PaintingViewer 
            paintings={props.firebase.orderedChildPaintings}
            id={params.id} 
        />
    );
}

export default withFirebase(ChildArtViewer);
import React from 'react';
import Header from '../../components/Header';
import portrait from '../../images/portrait-2.jpg';
import { withFirebase } from '../../services/Firebase';
import classes from './style.module.css';

const Bio = props => (
    <div className={classes.Container}>
        <Header text={"Maria Bonner"} />
        <img className={classes.Portrait} src={portrait} alt="Portrait" />
        <div>
            {/* <h3 className={classes.heading}>Heading</h3> */}
            <p className={classes.paragraph}>
                Hi
            </p>
            <p className={classes.paragraph}>
                Great, you visited my web-site!
            </p>
            <p className={classes.paragraph}>
                For those of you who do not know me personally, I am a hobby illustrator dedicated to depicting chosen moments of my life and thoughts as illustrations. During the process of working on these illustrations, I experience these moments again, which helps me get the most out of them. It is the way to calm down, it is a way for self-therapy, it is a way to share something from deep inside, which I fail to express with words, it is a way to remember myself, where I am from, about people who believed, inspired and supported my talent. Over the last years, illustration became much more than simple drawing. 
            </p>
            <p className={classes.paragraph}>
            I started to draw again after I gave birth to my son. I am thankful to him for giving me a chance to take a pause in my IT career and think about my personality and what kind of person I would be for him. Illustration turned out to be an excellent therapy during the pauses, when he slept. I am happy I can still find time for drawing.
            </p>

            <p className={classes.paragraph}>
            I live in the lovely region of Nürnberg in Germany. I love the nature in this region. It is never hot or cold, rainy or too hot. We can go out every day of the year. I am convinced biergartens and bakeries here are the best. All of this makes life with a kid quite comfortable.
            </p>

            <p className={classes.paragraph}>
                If you like my illustrations, feel free to leave me a message!
            </p>

        </div>
    </div>
)

export default withFirebase(Bio);